<template>
  <!-- 产品中心 -->
  <div class="zz_product2">
    <div
      class="current-nav centerText"
      :style="{
        background: `url(${newsData.new_img}) 100%/100% no-repeat`
      }"
    >
      <h1>{{ newsData.new }}</h1>
      <h2>{{ newsData.new_text }}</h2>
      <span>产品</span>
    </div>
    <ul class="zz_product2_type">
      <a
        :href="'#/zx_news?currentIdx=' + head_active"
        v-for="(item, idx) in sjHead"
        :key="idx"
        :class="[head_active == idx ? 'xz_type' : '']"
        @click="switchList(item, idx)"
      >
        {{ item.name }}
      </a>
    </ul>
    <div class="zz_product2_nav" v-if="!currentId">
      <ul class="zz_product2_list" v-if="sjList.length != 0">
        <li v-for="(item, idx) in sjList" :key="idx" @click="goDetails(item)">
          <a
            :href="
              '#/zx_news?currentIdx=' + head_active + '&currentId=' + item.id
            "
          >
            <div class="list_image">
              <img
                :src="item.cover_image"
                alt=""
                style="width:100%;height: 100%;"
              />
              <div class="all_mark">
                <span>——</span>
                <div class="mark_div two_ellipsis">{{ item.title }}</div>
                <span>——</span>
              </div>
            </div>
            <div class="list_title ellipsis">{{ item.title }}</div>
          </a>
        </li>
        <onLoading :show="loading"></onLoading>
      </ul>
      <div class="details_empty" v-else>
        <img
          src="@/assets/image/xwdt/xwdt-data.webp"
          alt=""
          style="margin:0 auto"
        />
      </div>
      <div class="all-pagination">
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page.sync="form.page"
          background
          :page-size="8"
          layout="prev, pager, next,total"
          :total="total"
          v-if="total > 8"
        >
        </el-pagination>
        <span class="no_meet_conditions" v-else>暂无更多数据</span>
      </div>
    </div>
    <div class="zz_product2_nav2" v-else>
      <div class="nav2_html" v-html="sjData.maincontent"></div>
    </div>
  </div>
</template>

<script>
import { getXwTypeData, getAllXwData, getXwxqData, getPageMsg } from '@/api/api'
import onLoading from '@/common/Loading/onLoading.vue'
export default {
  components: { onLoading },
  data () {
    return {
      sjHead: [],
      sjList: [],
      head_active: 0,
      form: {
        page: 1,
      },
      total: 0,
      loading: false,
      currentId: 0,
      sjData: {},
      newsData: {},
      type_id: '',
      project: ''
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentIdx) {
          this.head_active = this.$route.query.currentIdx
        } else {
          this.head_active = 0
          this.type_id = ''
          this.getSjdqList(1, '', this.project)
        }
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
          this.goDetails({ id: this.currentId })
        } else {
          this.currentId = 0
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      let that = this
      if (this.$route.query.currentIdx) {
        this.head_active = this.$route.query.currentIdx
      } else {
        this.head_active = 0
      }
      if (this.$route.query.currentId) {
        this.currentId = this.$route.query.currentId
        this.goDetails({ id: this.currentId })
      } else {
        this.currentId = 0
      }
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.newsData = res.data.data.新闻
        }
      })
      that.project = sessionStorage.getItem(this.changeData() + 'project')
      getXwTypeData(1, that.project).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: '全部',
            id: ''
          })
          that.sjHead = res.data.data
        }
      })
      that.getSjdqList(1, '', that.project)
    },
    getSjdqList (i, x, d) {
      this.loading = true
      getAllXwData(i, x, d).then(res => {
        if (res.data.code == 1) {
          this.sjList = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    handleCurrentChange (val) {
      this.form.page = val
      this.getSjdqList(val, this.type_id, this.project)
    },
    switchList (e, i) {
      this.head_active = i
      this.currentId = 0
      this.type_id = e.id
      this.getSjdqList(1, e.id, this.project)
    },
    goDetails (e) {
      this.currentId = e.id
      this.sjData = {}
      getXwxqData(e.id, this.project).then(res => {
        if (res.data.code == 1) {
          this.sjData = res.data.data
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.zz_product2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .zz_product2_type {
    width: 15rem;
    background-color: #fff;
    margin: 3% auto;
    border-bottom: 1px solid #9a6634;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      font-size: 0.16rem;
      color: #333333;
      position: relative;
      text-align: center;
      width: 1.6rem;
      padding: 0.25rem 0.15rem;
      cursor: pointer;
    }
    a:hover,
    .xz_type {
      background-color: #9a6634;
      color: #fff;
    }
  }
  .zz_product2_list,
  .details_empty {
    width: 15.2rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    li {
      padding: 0.05rem 0.1rem;
      display: inline-block;
      font-size: 0.18rem;
      margin-top: 0.2rem;
      vertical-align: middle;
      width: calc(33.3333% - 0.2rem);
      cursor: pointer;
      .list_image {
        width: 100%;
        height: 240px;
        position: relative;
      }
      .list_title {
        padding: 0.2rem;
        background-color: #fff;
      }
    }
    li:hover {
      color: #9a6634;
      .all_mark {
        height: 100%;
      }
    }
  }
  .details_empty {
    width: 15rem;
    background-color: #fff;
  }
  .zz_product2_nav2 {
    width: 15.2rem;
    margin: 50px auto;
    .nav2_html {
      line-height: 1.5;
      /deep/img {
        margin: 0 auto !important;
        display: block;
      }
      /deep/ div{
        max-width: 100%;
      }
    }
  }
  .all-pagination {
    padding-bottom: 35px;
  }
}
</style>
